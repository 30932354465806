.navContainer {
    background-color: #FFF;
    padding: 8px 16px;
    display: flex !important;
    align-items: center !important;
    }
   

   .navContainer .MuiToolbar-root {
    display: unset !important;
   }
.bg-color-custom.MuiDrawer-docked .MuiDrawer-paper{
    background-color: #e9e9ea;
}

.navContainer.header-shadow{
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    border-bottom: 1px solid #eee;
}