.header-row {
  position: sticky;
  top: 0;
  background-color: #fff; /* Set a background color for the header row */
  font-weight: bold; /* Make the text bold */
}

.Body-row {
  background-color: #fff; /* Set a background color for the header row */
  font-size: small;
}
/* 
.toolbar {
  min-height: 52px;
  padding-right: 2px;
  display: flex;
  align-items: baseline;
} */
.MuiToolbar-root {
  display: flex !important;
  align-items: baseline !important;
}
/* 
.toolbar .MuiTablePagination-actions button {
  margin: 0 5px;
} */
.MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.675rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 6px 16px;
}
